
angular.module("pgc", ['ngRoute', 'ngSanitize']);
angular.module("pgc").constant("config", {
    baseUrl: "http://"+  window.location.hostname+ "/api"
});
angular.module("pgc").factory("ajaxAPI", ['$http', 'config', function ($http, config) {
    var _postRota = function (urlDesstino, dados) {
        return  $http({method: "POST",
            url: urlDesstino,
            headers: {"Accept": " application/json"},
            data: dados
        });
    };
    return {     
        postRota: _postRota      
    };
}]);
angular.module("pgc").controller('contatoController', ['$scope','ajaxAPI', function ($scope, ajaxAPI) {
    $scope.mensagem="enviando, por favor aguarde!!";
    $scope.exibir=false;
    $scope.confirmar = function (item) {
         $scope.mensagem="enviando, por favor aguarde!!";
          $scope.exibir=true;
        ajaxAPI.postRota('/contato', item).success(function (data) {
            console.log(data);
               $scope.exibir=false;
            delete $scope.dados;
            $scope.itemForm.$setPristine();
            jQuery('#modalConfirmacaoSim').modal('hide');
            alert('Enviado com sucesso!');
        }).error(function (data, status) {
            $scope.exibir=false;
            $scope.mensagem="Falha ao enviar. Atualize a pagina e tente novamente!";
        });
    };



}]);


